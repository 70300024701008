import React from 'react';
import {useTranslation} from "../lang/TranslationContext";


function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    const year = String(date.getFullYear()).slice(-2); // Get last 2 digits of the year
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}/${month}/${year}`;
}


const FriendItem = ({item}) => {
    const icon = "img/sprite/sprite.svg#";
    const { t } = useTranslation();
    return (
        <>
            <li className="task-list__item" key={item.id}>
                <div className="task-card">
                                        <span className="task-card__user">
                                            {/*<img width="30" height="30" src="./img/index/user.png" alt="image"/>*/}
                                        </span>
                    <div className="task-card__inner"><p>@{item.username}</p><span
                        className="task-card__value">{Number(item.amount).toFixed(4)} TON</span></div>
                    <span className="task-card__currency">+ {(Number(item.amount) * item.percent / 100).toFixed(4)} TON</span></div>
            </li>

        </>
    );
};

export default FriendItem;
