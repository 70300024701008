import React, {useEffect, useState} from 'react';
import WithdrawItem from '../components/WithdrawItem';
import {useTranslation} from "../lang/TranslationContext";
import Header from "../blocks/Header";


function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    const year = String(date.getFullYear()).slice(-2); // Get last 2 digits of the year
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}/${month}/${year}`;
}

function formatStatus(status) {
     let back = 'In progress'
    if (status == 1)
        back = "Completed"

    if(status == 2)
        back = "Canceled"

    return back
}


const Withdraw = ({tg, openModal, user, history, fetchHistory}) => {
  const { t } = useTranslation();

    useEffect(() => {
        fetchHistory();
    }, []);


    return (
        <>
            <main>
                <section className="miners-section">
                    <div className="container">
                        <div className="miners-section__box">
                            <div className="main-info mode">
                                <div className="main-info__top"><span className="main-info__icon">
                                    <img width="25"
                                                                                                       height="25"
                                                                                                       src="img/index/ton.png"
                                                                                                       alt="image"/> </span><span
                                    className="main-info__value"><b>{user && user['balance_3'] ? user['balance_3'] : 0}</b> TON</span></div>
                                <div className="main-info__btns">
                                    <button type="button" className="main-btn main-btn--mode" onClick={() => {
                                        openModal('wallet')
                                    }}>{t('Withdraw')}</button>
                                    <button type="button" onClick={() => {
                                        openModal('buy')
                                    }} className="main-btn">{t('Buy boost')}
                                        <svg width="22" height="20">
                                            <use href="img/sprite/sprite.svg#icon1"></use>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="miners-section__inner"><h1 className="title">{t('History transactions')}</h1>
                                {history.length == 0 ? (<>
                                    <div className="result" style={{'color':'black'}}>
                                        {t('No transactions')}
                                    </div>

                                </>) : (<>
                                    <ul className="wallet-list">

                                        {history && (
                                            <>
                                                {history.map((item) => (
                                                    <>
                                                        <li className="wallet-list__item">
                                                            <div className="wallet-card">
                                                                <div className="wallet-card__box">
                                                                    <button className="wallet-card__btn">
                                                                        <svg width="14" height="16">
                                                                            <use
                                                                                href="img/sprite/sprite.svg#arrow"></use>
                                                                        </svg>
                                                                    </button>
                                                                    <span className="wallet-card__title">{t('Received')}</span>
                                                                    <span
                                                                        className="wallet-card__text"
                                                                        data-short
                                                                        data-full-text="">{t(formatStatus(item.status))}</span>
                                                                </div>
                                                                <div className="wallet-card__inner"><span
                                                                    className="wallet-card__value">{Number(item.amount).toFixed(2)} <b>TON</b></span>
                                                                    <span
                                                                        className="wallet-card__date">{formatTimestampToDate(item.date)}</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </>
                                                ))}
                                            </>
                                        )}


                                    </ul>
                                </>)}

                            </div>
                        </div>
                    </div>
                </section>
            </main>


        </>
    );
};

export default Withdraw;
