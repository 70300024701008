import React from 'react';
import {NavLink} from 'react-router-dom';
import {useTranslation} from "../lang/TranslationContext";

const Footer = (page, setActivePage) => {
    const { t } = useTranslation();
    return (
      //   <ul className="bar">
      //       <li className="bar__item">
      //           <a href="wallet.html" className="bar__link">
      // <span className="bar__icon">
      //   <img width="20" height="15" src="img/sprite/icon2.svg" alt="image"/>
      // </span>Кошелек </a>
      //       </li>
      //       <li className="bar__item">
      //           <a href="tasks.html" className="bar__link">
      // <span className="bar__icon">
      //   <img width="20" height="20" src="img/sprite/icon3.svg" alt="image"/>
      // </span>Задачи </a>
      //       </li>
      //       <li className="bar__item">
      //           <a href="boost.html" className="bar__link">
      // <span className="bar__icon">
      //   <img width="22" height="20" src="img/sprite/icon4.svg" alt="image"/>
      // </span>Усиление </a>
      //       </li>
      //   </ul>
        <ul className="bar">
            <li className="bar__item  close">
                <NavLink to={"/withdraw"} className={({isActive}) => isActive ? "bar__link active" : "bar__link"}>
                        <span className="bar__icon">
                          <img width="20" height="15" src="img/sprite/icon2.svg" alt="image"/>
                        </span>
                    {t('Wallet')}
                </NavLink>
            </li>


            <li className="bar__item  close">
                <NavLink to={"/friends"} className={({isActive}) => isActive ? "bar__link active" : "bar__link"}>
                        <span className="bar__icon">
                          <img width="24" height="18" src="img/sprite/icon5.svg" alt="image"/>
                        </span>
                    {t('Friends')}
                </NavLink>
            </li>
            <li className="bar__item  close">
                <NavLink to={"/"}
                         className={({isActive}) => isActive ? "bar__link mode active" : "bar__link mode"}>
                    <img width="60" height="65" src="img/index/ton.png" alt="image"/>
                </NavLink>
            </li>


            <li className="bar__item  close">
                <NavLink to={"/tasks"} className={({isActive}) => isActive ? "bar__link active" : "bar__link"}>
                    <span className="bar__icon">
                       <img width="20" height="20" src="img/sprite/icon3.svg" alt="image"/>
                    </span>
                    {t('Tasks')}
                </NavLink>
            </li>

            <li className="bar__item  close">
                <a href="https://t.me/TwoTON_NEWs" className={"bar__link"} target="_blank">
                    <span className="bar__icon">
                      <img width="22" height="20" src="img/sprite/icon4.svg" alt="image"/>
                    </span>{t('Support')}
                </a>
            </li>

        </ul>
    )
        ;
};

export default Footer;
