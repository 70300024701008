import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useTranslation} from "../lang/TranslationContext";
import Header from "../blocks/Header";

const Tasks = ({tg}) => {
    const { t } = useTranslation();

    const [tasksHistory, setTasksHistory] = useState([]);
    const [tasksType, setTasksType] = useState(1);
    const [tasks, setTasks] = useState([]);
    const fetchTasksHistory = async () => {
        const initData = tg && tg.initData ? tg.initData : null;
            const response = await axios.get(`${process.env.REACT_APP_API_URL}getTasksHistory.php`, 
              {
                headers: {
                  'Authorization': initData,
                  'Content-Type': 'application/json'
              },
            }
            )
            .then(response => {
                setTasksHistory(response.data.history);
            }).catch(error => {
              console.log('Error fetching data:', error);
            });
    };

    const checkTask = async (task_id) => {
        try {
        const initData = tg && tg.initData ? tg.initData : null;
                const response = await fetch(`${process.env.REACT_APP_API_URL}checkTask.php`, {
                        method: 'POST',
                        headers: {
                                'Authorization': initData || '',
                                'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({'task_id': task_id}),
                });

                const result = await response.json();
                if (result.status === 'success') {
                    fetchTasksHistory()
                } else {
                    alert(result.message)
                }
        } catch (error) {
            console.log(error)
        }
};
const fetchTasks = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}getTasks.php`, 
      {
        headers: {
          'Content-Type': 'application/json'
      },
    }
    )
    .then(response => {
        setTasks(response.data.tasks);
    }).catch(error => {
      console.log('Error fetching data:', error);
    });
};
  

    const doTask = async (task) => {
        window.open(task.link, '_blank');
        setTimeout(() => {
            checkTask(task.id);
        }, 5000); 
    }
    
  
    useEffect(() => {
        fetchTasksHistory();
        fetchTasks();
    }, [tg]);

    return (
        <>
            <main>
                <section className="referral-section">
                    <div className="container">
                        <div className="main-top"><h1 className="main-top__title">{t('Tasks')}</h1></div>

                        <div className="referral-section__box">
                            <div className="main-top"><p>{t('Get guaranteed bonus power. Tasks are updated every day. Come in, complete them and get your bonuses!')}</p></div>
                            <ul className="task-list">
                                {tasks && tasks.map((task) => (

                                    <li className="task-list__item">
                                        {tasksHistory && (tasksHistory.includes(Number(task.id)) || tasksHistory.includes(String(task.id))) ? (
                                            <>
                                                <div className="task-card">
                                                                <span className="task-card__image">
                                                                 <img width="40" height="40" src="img/index/tg.png"
                                                                      alt="image"/>
                                                                </span>
                                                    <span
                                                        className="task-card__title">{t(task.name)}</span>
                                                    <span
                                                        className="task-card__text">{task.amount} gh/s</span>
                                                    <button className="main-btn main-btn--mode">{t('Complete')}</button>
                                                </div>
                                            </>

                                        ) : (
                                            <>
                                                <div className="task-card" onClick={() => {
                                                    doTask(task)
                                                }}>
                                                    <span className="task-card__icon">
                                                        <svg width="15" height="12">
                                                            <use
                                                            href="img/sprite/sprite.svg#tg"></use>
                                                        </svg>
                                                    </span>
                                                    <div className="task-card__inner"><p>{t(task.name)}</p>
                                                        <span className="task-card__value">{task.amount} gh/s</span>
                                                    </div>
                                                    <button className="main-btn ">{t('Start')}</button>
                                                </div>

                                            </>
                                        )}
                                    </li>))}

                            </ul>
                        </div>
                    </div>
                </section>
            </main>

        </>
    );
};

export default Tasks;
