import React, {useState, useEffect} from 'react';
import axios from 'axios';
import FriendItem from '../components/FriendItem';
import {useTranslation} from "../lang/TranslationContext";
import Header from "../blocks/Header";

const copyText = async (text) => {
    try {
        await navigator.clipboard.writeText(text);
        console.log(1);
        alert('Success')
    } catch (err) {
        console.error('Failed to copy: ', err);
    }
}


const Friends = ({tg}) => {
    const {t} = useTranslation();
    const [userId, setUserId] = useState(null);
    const [referralLink, setReferralLink] = useState('');

    // const [list, setList] = useState(null);
    const [list, setList] = useState([]);
    const [countRef, setCountRef] = useState(null);
    const [currencies, setCurrencies] = useState(true);
    const [upliner, setUpliner] = useState(false);

    useEffect(() => {
        let tmpUserId = ''
        if (tg && tg.initDataUnsafe && tg.initDataUnsafe.user) {
            const user = tg.initDataUnsafe.user;
            if (user && user.id) {
                tmpUserId = user.id;
                setUserId(user.id);
            }
            fetchFriends();
        }
        setReferralLink(`https://t.me/two_ton_bot/?start=${tmpUserId}`);
    }, [tg]);

    const fetchFriends = async () => {
        const initData = tg && tg.initData ? tg.initData : null;
        const response = await axios.get(`${process.env.REACT_APP_API_URL}getFriends.php`,
            {
                headers: {
                    'Authorization': initData,
                    'Content-Type': 'application/json'
                },
            }
        )
            .then(response => {
                setList(response.data.friendsIncome);
                setCountRef(response.data.friendsCount);
                setCurrencies(response.data.currencies);
                setUpliner(response.data.upliner)
            }).catch(error => {
                console.log('Error fetching data:', error);
            });
    };


    return (
        <>
            <main>
                <section className="referral-section">
                    <div className="container">
                        <div className="referral-section__box">
                            <div className="referral-section__wrapp">
                                <div className="main-top"><h1 className="main-top__title">{t('Invite your friends')}</h1>
                                    <p>{t('You and your friend will receive bonuses')}</p></div>
                                <form className="main-form friends">
                                    <button onClick={() => copyText(referralLink)} type="button" className="main-btn main-btn--blue">{t('Invite a friend')}</button>
                                    <label className="main-form__label" htmlFor="address_3" data-parent-copy><input
                                        readOnly="readonly"
                                        type="text"
                                        name="address_3"
                                        id="address_3"
                                        className="main-form__input"
                                        onClick={() => copyText(referralLink)}
                                        />
                                        <button type="button" onClick={() => copyText(referralLink)} className="copy-btn copy-btn--mode" data-copy><img width="18"
                                                                                                   height="18"
                                                                                                   src="img/sprite/copy2.svg"
                                                                                                   alt="image"/>
                                        </button>
                                    </label></form>
                            </div>
                            <div className="referral-section__top"><span className="referral-section__text">{t('History of referral accruals')}</span>
                                <div className="referral-section__icon">
                                    <img width="54" height="60" src="./img/index/cup.png" alt="image"/>
                                </div>
                                <span className="referral-section__value"></span>
                            </div>
                            <ul className="task-list">
                                {list.map((item) => (
                                    <FriendItem item={item}/>
                                ))}
                                {/*<li className="task-list__item">*/}
                                {/*    <div className="task-card">*/}
                                {/*        <span className="task-card__user">*/}
                                {/*            <img width="30" height="30" src="./img/index/user.png" alt="image"/></span>*/}
                                {/*        <div className="task-card__inner"><p>Play SEI Spin</p><span*/}
                                {/*            className="task-card__value">100 gh/s</span></div>*/}
                                {/*        <span className="task-card__currency">+ 1500 gh/s</span></div>*/}
                                {/*</li>*/}
                                {/*<li className="task-list__item">*/}
                                {/*    <div className="task-card"><span className="task-card__user"><img width="30"*/}
                                {/*                                                                      height="30"*/}
                                {/*                                                                      src="./img/index/user.png"*/}
                                {/*                                                                      alt="image"/></span>*/}
                                {/*        <div className="task-card__inner"><p>Play SEI Spin</p><span*/}
                                {/*            className="task-card__value">100 gh/s</span></div>*/}
                                {/*        <span className="task-card__currency">+ 1500 gh/s</span></div>*/}
                                {/*</li>*/}
                                {/*<li className="task-list__item">*/}
                                {/*    <div className="task-card"><span className="task-card__user"><img width="30"*/}
                                {/*                                                                      height="30"*/}
                                {/*                                                                      src="./img/index/user.png"*/}
                                {/*                                                                      alt="image"/></span>*/}
                                {/*        <div className="task-card__inner"><p>Play SEI Spin</p><span*/}
                                {/*            className="task-card__value">100 gh/s</span></div>*/}
                                {/*        <span className="task-card__currency">+ 1500 gh/s</span></div>*/}
                                {/*</li>*/}
                                {/*<li className="task-list__item">*/}
                                {/*    <div className="task-card"><span className="task-card__user"><img width="30"*/}
                                {/*                                                                      height="30"*/}
                                {/*                                                                      src="./img/index/user.png"*/}
                                {/*                                                                      alt="image"/></span>*/}
                                {/*        <div className="task-card__inner"><p>Play SEI Spin</p><span*/}
                                {/*            className="task-card__value">100 gh/s</span></div>*/}
                                {/*        <span className="task-card__currency">+ 1500 gh/s</span></div>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                    </div>
                </section>
            </main>

            <main>
                <section className="referral-section">
                    <div className="container">
                        <div className="referral-section__wrapp">
                            <h1 className="title">
                                <svg width="35" height="35">
                                    <use href="img/sprite/sprite.svg#megaphone2"></use>
                                </svg>
                                {t("Friends")}
                            </h1>
                            <p>{t('By inviting your friends, you can significantly increase your income. The program consists of two levels: at the first level you receive 6% of his profit, and at the second level - 3%')}</p>

                            <div className="referral-section__inner">
                                <form className="copy-form">
                                    <label className="copy-form__label" htmlFor="address_3" data-parent-copy>
                                        <input onClick={() => copyText(referralLink)} readOnly type="text"
                                               name="address_3" id="address_3" className="copy-form__input"
                                               value={referralLink}/>
                                        <button type="button" className="copy-btn"
                                                onClick={() => copyText(referralLink)}>
                                            <img width='24' height='24' src='img/sprite/Copy.svg' alt='image'/>
                                        </button>
                                    </label>
                                </form>

                                <div className="main-info">
                                    <div className="main-info__col">
                                        <span className="main-info__value"><b>6%</b> 1-{t("st")} {t("level")}</span>
                                    </div>
                                    <div className="main-info__col main-info__col--mode">
                                        <span className="main-info__value"><b>3%</b> 2-{t("st")} {t("level")}</span>
                                    </div>
                                </div>
                            </div>

                            <ul className="history-list">
                                <li className="history-list__row history-list__row--head" key={0}>
                                    <span className="history-list__name">{t('User')}</span>
                                    <span className="history-list__name">{t('Level')}</span>
                                    <span className="history-list__name">{t('Deposit')}</span>
                                    <span className="history-list__name">{t('Profit')}</span>
                                </li>

                            </ul>


                        </div>


                    </div>

                </section>
            </main>
        </>
    );
};

export default Friends;